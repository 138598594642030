import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  TablePaginationConfig,
} from "antd";
const { Option } = Select;
import { Layout } from "layout";
import {
  ClientAdministratorsClient,
  ClientAdministratorBriefDto,
  ClientAdministratorType,
  ClientUsersClient,
  SendResetPasswordEmailCommand,
  ForceUpdateClientAdministratorPasswordCommand,
} from "@api";
import { showError } from "@action";
import { FormattedMessage, useIntl } from "react-intl";
import ClientAdminMenu from "@components/Admin/ClientAdmins/ClientAdminMenu";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import ResetPasswordModal from "@components/Admin/ResetPasswordModal";
import ClientGroupCascaderFilter from "@components/Admin/ClientGroupCascaderFilter";
import LanguageSelectFilter from "@components/Admin/LanguageSelectFilter";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientAdmins", "ClientAdmins");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createUserForm] = Form.useForm();
  const [editUserForm] = Form.useForm();
  const [migrateUserForm] = Form.useForm();

  const clientAdministratorsClient = new ClientAdministratorsClient();
  const clientUsersClient = new ClientUsersClient();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [migrateModalVisible, setMigrateModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordUserId, setResetPasswordUserId] = useState("");

  const [state, setState] = useState({
    items: [] as ClientAdministratorBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "ClientAdmins")) return;

    setState({
      ...state,
      loading: true,
    });
    const res =
      await clientAdministratorsClient.getClientAdministratorWithPagination(
        _keywords ?? keywords,
        undefined,
        undefined,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
    setState({
      ...state,
      items: res.items ?? [],
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function OpenModalAddClientUser() {
    setCreateModalVisible(true);
  }

  async function handleAddClientAdmin() {
    try {
      const values = await createUserForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await clientAdministratorsClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateModalVisible(false);
          createUserForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelAddClientAdmin() {
    setCreateModalVisible(false);
    createUserForm.resetFields();
  }

  function OpenModalEditClientAdmin(record: ClientAdministratorBriefDto) {
    setEditModalVisible(true);
    editUserForm.setFieldsValue(record);
  }

  async function handleEditClientAdmin() {
    try {
      const values = await editUserForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientAdministratorsClient.update(values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setEditModalVisible(false);
          editUserForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelEditClientAdmin() {
    setEditModalVisible(false);
    editUserForm.resetFields();
  }

  function OpenModalResetPassword(id: string) {
    setResetPasswordModalVisible(true);
    setResetPasswordUserId(id);
  }

  const handleResetPassword = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    setResetPasswordModalVisible(false);

    try {
      if (data.type === 1)
        await clientUsersClient.sendResetPasswordEmail(
          new SendResetPasswordEmailCommand({
            userId: resetPasswordUserId,
            defaultLanguageId: data.defaultLanguageId,
          })
        );
      else
        await clientAdministratorsClient.forceChangePassword(
          new ForceUpdateClientAdministratorPasswordCommand({
            userId: resetPasswordUserId,
            newPassword: data.newPassword,
          })
        );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelResetPassword = () => {
    setResetPasswordModalVisible(false);
  };

  function OpenModalMigrateClientAdmin(record: ClientAdministratorBriefDto) {
    setMigrateModalVisible(true);
    migrateUserForm.setFieldsValue(record);
  }

  async function handleMigrateClientAdmin() {
    try {
      const values = await migrateUserForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientAdministratorsClient.changeClientGroup(values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setMigrateModalVisible(false);
          migrateUserForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelMigrateClientAdmin() {
    setMigrateModalVisible(false);
    migrateUserForm.resetFields();
  }

  function handleResendEmail(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmResendMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticSendingMessage" />
        );
        try {
          await clientAdministratorsClient.sendNotification(id);
          messageApi.success(
            <FormattedMessage id="StaticSendSuccessMessage" />
          );
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleArchiveClientAdmin(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmArchiveAdmin" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientAdministratorsClient.archiveClientAdministrator(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    {
      // title: "Client Name",
      title: <FormattedMessage id="ClientAdminTableHeaderClientName" />,
      dataIndex: "clientName",
      key: "clientName",
      sorter: true,
    },
    {
      // title: "Client Group",
      title: <FormattedMessage id="ClientAdminTableHeaderClientGroup" />,
      dataIndex: "clientGroupName",
      key: "clientGroupName",
      sorter: true,
    },
    {
      // title: "Type",
      title: <FormattedMessage id="ClientAdminTableHeaderType" />,
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (t: ClientAdministratorType) => <>{ClientAdministratorType[t]}</>,
    },
    {
      // title: "Login ID",
      title: <FormattedMessage id="ClientAdminTableHeaderLoginID" />,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
    },
    {
      // title: "Full Name",
      title: <FormattedMessage id="ClientAdminTableHeaderFullName" />,
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
    },
    {
      // title: "Language",
      title: <FormattedMessage id="ClientAdminTableHeaderLanguage" />,
      dataIndex: "preferredLanguageDisplayName",
      key: "preferredLanguageDisplayName",
      sorter: true,
    },
    {
      // title: "Email",
      title: <FormattedMessage id="ClientAdminTableHeaderEmail" />,
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      // title: "Time Added",
      title: <FormattedMessage id="ClientAdminTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (text: any, record: ClientAdministratorBriefDto) =>
        record.created?.toLocaleString(),
    },
    {
      // title: "Time Modified",
      title: <FormattedMessage id="ClientAdminTableHeaderTimeModified" />,
      dataIndex: "lastModified",
      key: "lastModified",
      sorter: true,
      defaultSortOrder,
      render: (text: any, record: ClientAdministratorBriefDto) =>
        record.lastModified?.toLocaleString(),
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientAdminTableHeaderAction" />,
      render: (text: any, record: ClientAdministratorBriefDto) => (
        <Space>
          {auth.can(ACTION.Edit, "ClientAdmins") ? (
            <Button
              type="primary"
              onClick={() => OpenModalEditClientAdmin(record)}
            >
              <Space>
                <FormattedMessage id="ClientAdminClientAdminTableEditButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "ResetPassword") ? (
            <Button
              type="primary"
              onClick={() => OpenModalResetPassword(record.id as string)}
            >
              <Space>
                <FormattedMessage id="StaticResetPasswordButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "Migrate") ? (
            <Button
              type="primary"
              onClick={() => OpenModalMigrateClientAdmin(record)}
            >
              <Space>
                <FormattedMessage id="ClientAdminClientAdminTableMigrationButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "ResendRegistration") ? (
            <Button
              type="primary"
              onClick={() => handleResendEmail(record.id as string)}
            >
              <Space>
                <FormattedMessage id="ClientAdminClientAdminTableSendRegEmailButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Archive, "ClientAdmins") ? (
            <Button
              type="primary"
              onClick={() => handleArchiveClientAdmin(record.id as string)}
            >
              <Space>
                <FormattedMessage id="ClientAdminClientAdminTableArchiveButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ClientAdminClientAdminNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <ClientAdminMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "ClientAdmins") ? (
                <Button type="primary" onClick={OpenModalAddClientUser}>
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="ClientAdminClientAdminContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <Modal
        visible={createModalVisible}
        title={<FormattedMessage id="ClientAdminClientAdminModalAddTitle" />}
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddClientAdmin}
        onCancel={handleCancelAddClientAdmin}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={createUserForm} {...formItemLayout}>
          <Form.Item
            name="clientGroupId"
            label={
              <FormattedMessage id="ClientGroupClientGroupStaticSelectLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientGroupClientGroupStaticMissingSelectRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <ClientGroupCascaderFilter
              styleProps={{ width: "100%" }}
              handleAction={(value) => {
                createUserForm.setFieldsValue({
                  clientGroupId: value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="type"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticTypeLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticMissingTypeRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Select placeholder="select your type">
              {Object.keys(ClientAdministratorType)
                .filter((v) => !isNaN(Number(v)))
                .map((key) => (
                  <Option key={key} value={Number(key)}>
                    {ClientAdministratorType[Number(key)]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="userName"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticLoginIDLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticFullNameLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticPasswordLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="hasEmailNotification"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticEmailNotificationLabel" />
            }
            valuePropName="checked"
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasEmailNotification !==
              currentValues.hasEmailNotification
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="email"
                label={
                  <FormattedMessage id="ClientAdminClientAdminStaticEmailLabel" />
                }
                rules={[
                  {
                    type: "email",
                    message: (
                      <FormattedMessage id="ClientAdminClientAdminStaticInvalidEmailRule" />
                    ),
                  },
                  {
                    required: getFieldValue("hasEmailNotification"),
                    message: (
                      <FormattedMessage id="ClientAdminClientAdminStaticMissingEmailRule" />
                    ),
                  },
                ]}
                initialValue=""
              >
                <Input />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticLanguageLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticMissingLanguageRule" />
                ),
              },
            ]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                createUserForm.setFieldsValue({ preferredLanguageId: value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={editModalVisible}
        title={<FormattedMessage id="ClientAdminClientAdminModalEditTitle" />}
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleEditClientAdmin}
        onCancel={handleCancelEditClientAdmin}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={editUserForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticFullNameLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticMissingFullNameRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticEmailLabel" />
            }
            rules={[
              {
                type: "email",
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticInvalidEmailRule" />
                ),
              },
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticMissingEmailRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="ClientAdminClientAdminStaticLanguageLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientAdminClientAdminStaticMissingLanguageRule" />
                ),
              },
            ]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                editUserForm.setFieldsValue({ preferredLanguageId: value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={migrateModalVisible}
        title={
          <FormattedMessage id="ClientAdminClientAdminModalMigrationTitle" />
        }
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleMigrateClientAdmin}
        onCancel={handleCancelMigrateClientAdmin}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={migrateUserForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="clientGroupId"
            label={
              <FormattedMessage id="ClientGroupClientGroupStaticSelectLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientGroupClientGroupStaticMissingSelectRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <ClientGroupCascaderFilter
              styleProps={{ width: "100%" }}
              handleAction={(value) => {
                migrateUserForm.setFieldsValue({
                  clientGroupId: value,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <ResetPasswordModal
        visible={resetPasswordModalVisible}
        handleAction={handleResetPassword}
        handleCancelAction={handleCancelResetPassword}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;

import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const ClientMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "ClientAdminClientAdminNavigationTitle",
        path: "/admin/clientadmins",
        page: "ClientAdmins",
        subpage: "ClientAdmins",
      },
    ]}
  />
);

export default ClientMenu;
